<template>
  <div class="vision">
    <div class="container">
      <div class="inner">
        <img src="/assets/img/page.company.vision.jpg" alt="회사 비전"/>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageCompanyVision";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.vision {
  margin-bottom: $px70;

  .inner {
    margin-top: $px20;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: $px60 $px20 $px40;
    text-align: center;
  }
}
</style>